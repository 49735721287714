import JWPlayer from './JWPlayer';
import { getParam, getAccessToken, getState } from './utils';

class CriticalVideoLoader {
  constructor () {
    // TODO: remove data-psu later on
    this.playerTagSelector = '.deltaPlayer[data-video-id]:not([id], .deltaPlayer[data-psu]:not([id])';
    this.incrnum = 0;
    if (!window.fngPlayers) { window.fngPlayers = []; }
    this.segmentAnalytics = window.SegmentAnalytics;
    this.loadVideos = this.loadVideos.bind(this);
    this.loadScript = this.loadScript.bind(this);
    this.loadEmbedPlayer = this.loadEmbedPlayer.bind(this);
    this.loadPageVideos = this.loadPageVideos.bind(this);
    this.isWatchPage = document.querySelector('.watch-page')
  }

  isVideoDataAutoplay (data) {
    return data && data.autoplay;
  }

  /**
     * Load videos on the page when mark up exists
     */
  loadPageVideos () {
    if (document.querySelector(this.playerTagSelector) !== null) {
      this.loadScript().then((Player) => {
        this.loadVideos(Player, null, true);
      });
    }
  }

  getVideoElementData (elem) {
    const elemdata = {};
    const idnum = ++this.incrnum;
    const randNum = Math.floor(Math.random().toFixed(2) * 100);
    if (elem instanceof Element) {
      const id = +idnum + randNum.toString();
      const videoId = elem.getAttribute('data-video-id');
      const psu = elem.getAttribute('data-psu');
      const location = elem.getAttribute('data-post-location');
      const autoplay = elem.getAttribute('data-autoplay');
      const mute = elem.getAttribute('data-mute');
      const image = elem.getAttribute('data-image-src');
      const focus = elem.getAttribute('data-focus-on-load')
      if (!videoId || !elem.id) {
        elem.id = 'Video' + id;
        elemdata.elemId = elem.id;
        elemdata.vid = videoId;
        elemdata.postLocation = location;
        elemdata.psu = psu;
        elemdata.image = image
        if (autoplay) {
          elemdata.autoplay = autoplay === 'true';
        }
        if (mute) {
          elemdata.mute = mute === 'true';
        }
        if (focus) {
          elemdata.focusOnLoad = focus === 'true';
        }
      }
      elem.parentElement.id = 'video-container-' + id;
      elemdata.container = elem.parentElement.id;
    }
    return elemdata;
  }

  /**
     * assigns a random id to the div element where the video is being played on
     * @param {*} elem
     */
  getRandomVideoId (elem) {
    const idnum = ++this.incrnum;
    const randNum = Math.floor(Math.random().toFixed(2) * 100);

    if (elem instanceof Element) {
      const id = +idnum + randNum.toString();
      if (!elem.id) {
        elem.id = 'Video' + idnum + randNum.toString();
      }
      elem.parentElement.id = 'video-container-' + id;
    }
    return elem.id;
  }

  getVideoElementsData () {
    return [].slice.call(document.querySelectorAll(this.playerTagSelector)).map(this.getVideoElementData.bind(this));
  }

  ArrayContainsVideo (arr, elemId) {
    return arr.includes(elemId);
  }

  /**
     * Loads a video player
     * @param {*} FNGPlayer
     * @param {*} elem
     * @param {*} data
     */
  async loadPlayer (FNGPlayer, elem, data) {
    if (data) {
      data.elemId = this.getRandomVideoId(elem);
      data.container = elem.parentElement.id;
      if (!window.fngPlayers) { window.fngPlayers = []; }
      if (!this.ArrayContainsVideo(window.fngPlayers, data.elemId)) {
        if (data.auth) {
          this.initPlayer(FNGPlayer, data);
        } else {
          data.auth = await this.getProfileData();
          this.initPlayer(FNGPlayer, data);
        }
      }
    }
  }

  async loadEmbedPlayer () {
    const urlQuery = location.search;
    const vid = getParam(urlQuery, 'id');
    const image = getParam(urlQuery, 'image');
    const analyticsProps = getParam(urlQuery, 'props');
    // analytics props
    let data = {}
    const player = window.FNGPlayer;
    if (player && vid) {
      data.elemId = 'video-player';
      data.embed = true;
      data.vid = vid
      data.videoType = 'clip'
      data.enablePlaylist = false;
      data.watch = {
        streamId: vid,
        streamType: 'vod'
      }
      data.auth = {
        profile: {
          accessToken: getAccessToken()
        }
      }
      data.isWatchPage = this.isWatchPage
      data.image = image
      if (analyticsProps) {
        const decodedAnalyticsProps = JSON.parse(atob(analyticsProps));
        data.embedAnalyticsProps = decodedAnalyticsProps
      }

      this.initPlayer(player, data, false);
    }
  }

  /**
     * loads short form videos on page load using data attribute
     * @param {*} Player
     * @param {*} videoElementsData
     * @param {*} sendPageView
     */
  loadVideos (Player, videoElementsData, sendPageView = false) {
    const context = this;
    const videos = videoElementsData || context.getVideoElementsData();
    if (videos.length > 0) {
      videos.map(async (videoData) => {
        if (!context.ArrayContainsVideo(window.fngPlayers, videoData.elemId)) {
          videoData.videoType = 'clip'
          const data = { ...videoData };
          data.auth = await this.getProfileData();
          context.initPlayer(Player, data, sendPageView);
        }
      });
    }
  }

  /**
   * Pushes JWPlayer into chartbeat tracking array
   * @param {*} player 
   */
  pushCBV (player) {
    if (!window._cbv) { window._cbv = []; }
    if (player) { window._cbv.push(player); }
  }

  initPlayer (player, data, sendPageView = false) {
    data.sendPageView = sendPageView;
    const videoPlayer = new JWPlayer(player, data);
    window.fngPlayers.push(videoPlayer);
    if (videoPlayer && videoPlayer.mode !== "live" && videoPlayer.player) {
      this.pushCBV(videoPlayer.player.player);
    }
  }

  /**
     * loads video player script and it's defaulted to dev if fs-prop meta tag is not found
     * Example meta tag: <meta fs-prop="video" script="//player.foxdcg.com/v3/sports"/>
     */
  loadScript () {
    return new Promise((resolve, reject) => {
      if (typeof FNGPlayer === 'function') {
        resolve(FNGPlayer);
      } else {
        // Create a new script and setup the basics.
        const script = document.createElement('script');
        const firstScript = document.getElementsByTagName('script')[0];

        script.async = false;
        script.src = (() => {
          const metaEl = document.querySelector('[fs-prop="video"]');
          let scripturl = metaEl && metaEl.getAttribute('script');

          if (scripturl === null) {
            scripturl = '//dev.player.fox.com/v3/sports.js';
          }

          if (window.location.search.includes('debugVideoPlayer')) {
            if (scripturl.includes('?')) { scripturl = scripturl + '&debug=all'; } else { scripturl = scripturl + '?debug=all'; }
          }

          return scripturl;
        })(window.location);
        // Handle the case where an optional callback was passed in.
        script.onload = function () {
          if (typeof FDM_Player === 'function') {
            resolve(FDM_Player);
          } else if (typeof FNGPlayer.default === 'function') {
            resolve(FNGPlayer);
          } else {
            throw new TypeError('Failed to load player script');
          }
          // Clear it out to avoid getting called more than once or any memory leaks.
          script.onload = script.onreadystatechange = undefined;
        };

        script.onerror = () => {
          reject();
        };

        script.onreadystatechange = function () {
          if (script.readyState === 'loaded' || script.readyState === 'complete') {
            script.onload();
          }
        };

        // Attach the script tag to the page (before the first script) so the magic can happen.
        firstScript.parentNode.insertBefore(script, firstScript);
      }
    });
  }

  async getJWT () {
    const wisReg = window.wisRegistration;
    if (wisReg) {
      const isUserLoggedIn = wisReg.isUserLoggedIn();
      // second param is to skip MVPD JWT and only get FSCOM or Anon JWT
      const jwt = await wisReg.getJWT(isUserLoggedIn, true);
      return jwt
    }
    return '';
  }
  /**
   * 
   * @returns auth data for playback
   */
  async getProfileData () {
    const state = JSON.parse(getState());
    return {
      profile: {
        profileAnonymous: !state.isLoggedInFox,
        profileId: state.foxLocalStorage ? state.foxLocalStorage.profileId : 
          state.anonLocalStorage ? state.anonLocalStorage.profileId : '',
        accessToken: await this.getJWT(),
      },
      mvpdId: state.mvpdLocalStorage ? state.mvpdLocalStorage.mvpd : ''
    }
  }
}

window.criticalVideoLoader = new CriticalVideoLoader();

export default CriticalVideoLoader;
