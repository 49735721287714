export function getParam (str, key) {
  const regexS = '[\\?&]' + key + '=([^&#]*)';
  const regex = new RegExp(regexS);
  const results = regex.exec(str);
  if (results == null) {
    return '';
  } else {
    return results[1];
  }
}

/**
 * reformats the release url to a jw player supported release url
 */
export function reformatReleaseUrl (releaseUrl) {
  // constructing new release url format if url contains old format
  if (releaseUrl && releaseUrl.includes('mbr=true')) {
    const releaseId = releaseUrl.match(/BKQ29B\/(.*?)\?/)[1];
    return releaseUrl = 'https://link.theplatform.com/s/BKQ29B/' + releaseId + '?formats=m3u';
  }
  return releaseUrl;
}

/**
 * get duration in seconds. hours may need to be supported later on
 * @param string duration - expects something like "0:50"
 * @return int - video duration
 */
export function convertTimeToSeconds (time) {
  if (typeof time === 'number') { return time; }
  return time ? time
    .split(':')
    .reverse()
    .map((i, idx) => (+i) * 60 ** idx)
    .reduce((acc, curr) => acc + curr, 0) : 0;
}

/**
 * get page type from analytics tag
 */
export function getPageType () {
  const metaEl = document.querySelector('[fs-prop="analytics"]');
  if (metaEl) {
    return metaEl.getAttribute('page-type');
  }
  return '';
}
/**
 * Google Tracking Manager helper method
 * @param {*} action
 * @param {*} type
 *'DC-[floodlightConfigID]/[activityGroupTagString]/[activityTagString]+[countingMethod]' - https://support.google.com/searchads/answer/7566546
 */
export function GTMTracking (props) {
  // actvity group tag string + counting method for all videos
  const activityGrpCntUnqAll = 'allvi00+unique';
  const activityGrpCntStdAll = 'allvi0+standard';
  // all videos
  const groupNameAll = 'allvs';
  // prepare data for long form/short form
  // long form
  let groupName = 'lfvs';
  let activityGrpCntStd = 'alllo0+standard';
  let activityGrpCntUnq = 'alllo00+unique';
  if (props.isClip) {
    groupName = 'sfvs';
    activityGrpCntStd = 'allsh0+standard';
    activityGrpCntUnq = 'allsh00+unique';
  }
  // check if gtag
  if (typeof window.gtag === 'function') {
    const trackingData = props.trackingData;
    const videoLength = props.videoContentLength;
    // send gtm for standard data for all videos
    sendFloodlight(groupNameAll, activityGrpCntStdAll, videoLength, trackingData);
    // send gtm for unique data for all videos
    sendFloodlight(groupNameAll, activityGrpCntUnqAll, videoLength, trackingData);
    // send data if either long form or short form for standard and unique
    sendFloodlight(groupName, activityGrpCntStd, videoLength, trackingData);
    sendFloodlight(groupName, activityGrpCntUnq, videoLength, trackingData);
  }
}

function sendFloodlight (groupName, activityMethod, videoLength, trackingData) {
  const floodlightConfigID = '8763171';
  const data = {
    allow_custom_scripts: true,
    u2: location.origin,
    send_to: `DC-${floodlightConfigID}/${groupName}/${activityMethod}`
  };

  const propKeys = {
    livestream: 'u12',
    program: 'u10',
    video_content_type: 'u11',
    video_content_length_format: 'u13',
    pod_id: 'u14',
    video_network: 'u8',
    video_sports_type: 'u9',
    sid: 'u15'
  };

  Object.keys(propKeys).forEach((key) => {
    const k = propKeys[key];
    const v = trackingData[key];
    if (v === undefined) {
      return;
    }

    data[k] = v + '';
  });

  if (videoLength) { data.u6 = videoLength; }
  window.gtag('event', 'conversion', data);
}

export function getReleaseId (releaseUrl) {
  if (!releaseUrl) { return; }
  releaseUrl = decodeURIComponent(releaseUrl);
  const releaseId = releaseUrl.match(/BKQ29B\/(.*?)\?/)[1];
  return releaseId || null;
}

export function FbTracking (props) {
  if (typeof window.fbq !== 'function') { return; }

  const id = '2492427060801049';
  const eventName = 'trackSingleCustom';

  window.fbq(eventName, id, 'All Video Starts');
  window.fbq(eventName, id, `${props.isClip ? 'Short' : 'Long'}-Form Video Starts`);
}

/**
 * gets access token from LS for embed players
 * @returns 
 */
export function getAccessToken () {
  const localStorage = window.localStorage
  const usrJwt = localStorage.getItem('_WIS_user_tokens') || localStorage.getItem('_WIS_userJWT') || localStorage.getItem('_WIS_anon_userJWT')
  if (usrJwt) {
    const jwtObj = JSON.parse(usrJwt);
    return jwtObj.item.accessToken
  }
  return undefined
}

export function getState () {
  return localStorage.getItem('_WIS_usr_state')
}
