class TrackingUtils {
  constructor () {
    this.isLogEnabled = false;
    this.isBB = this.bodySection ? this.bodySection.classList.contains('body-content-big-board') : false;
    this.checkLogEnabled();
  }

  log (message) {
    this.isLogEnabled && console.log(message);
  }

  checkLogEnabled () {
    this.isLogEnabled = (location.href.includes('trackinglog=true'));
  }

  /**
     * set tracking for link element
     * @param {*} links
     * @param {*} service
     * @param {*} trackingObj
     *     Example:
     *        TrackingUtils.setLinkTracking(document.querySelectorAll('.more-video > a.fs-more-btn'),'parsely',{
     *            'utm_campaign'  : 'more_videos',
     *            'utm_medium'    : 'fscom',
     *            'utm_content'   : 'morelink'
     *        });
     */
  setLinkTracking (links, service, trackingObj) {
    if (!links || !links.length || !service) { return; }

    if (links.constructor.name === 'NodeList') {
      links = [...links];
    }

    for (let i = 0, lLen = links.length; i < lLen; i++) {
      const link = links[i];
      if (link.hasAttribute('data-link-tracked-by')) { break; }

      if (service === 'parsely') {
        link.addEventListener('click', (e) => { this.ParselyTrackLink(e, trackingObj); });
      }
      link.setAttribute('data-link-tracked-by', service);
    }
  }

  /**
     * add to target's event.addEventListener
     * @param {*} ev
     * @param trackingObj
     */
  ParselyTrackLink (ev, trackingObj) {
    if (ev.preventDefault) { ev.preventDefault(); }

    const target = ev.currentTarget || ev.target;
    const url = target.getAttribute('href');
    const urlToTrack = target.getAttribute('data-url-to-track');

    if (typeof PARSELY !== 'undefined' && typeof PARSELY.beacon === 'object' && typeof PARSELY.beacon.trackPageView === 'function') {
      const parselyObj = {
        url: this.addTrackingQuery(trackingObj, urlToTrack || url),
        js: 1
      };

      PARSELY.beacon.trackPageView(parselyObj);
    }

    window.open(url, target.getAttribute('target') || '_self');
  }

  /**
     * constructing url param by using JS object (wrapping function)
     * @param {*} trackingObj
     * @param {*} url
     */
  addTrackingQuery (trackingObj, url) {
    for (const i in trackingObj) {
      const val = trackingObj[i];
      if (val) {
        url = this.updateQueryStringParam(url, i, val);
      }
    }
    return url;
  }

  /**
     * constructing url param by using JS object (actual logic)
     * based on "https://stackoverflow.com/a/6021027"
     * @param {*} uri
     * @param {*} key
     * @param {*} value
     */
  updateQueryStringParam (uri, key, value) {
    const re = new RegExp('([?&])' + key + '=.*?(&|#|$)', 'i');
    if (uri.match(re)) {
      return uri.replace(re, '$1' + key + '=' + value + '$2');
    } else {
      let hash = '';
      if (uri.includes('#')) {
        hash = uri.replace(/.*#/, '#');
        uri = uri.replace(/#.*/, '');
      }
      const separator = uri.includes('?') ? '&' : '?';
      return uri + separator + key + '=' + value + hash;
    }
  }

  /**
     * Replace empty spaces with dashes
     * @param {string} str
     */
  replaceSpacesWithDash (str) {
    return str.replace(/ /g, '-');
  }

  /**
     * get vide title, image and section from video player info
     * @returns Object {{title: string, image_url: string}}
     */
  getTrackingMeta (player) {
    let title = '';
    let thumbnail = '';
    let section = '';
    let id = '';
    let url = '';

    if (typeof player.videoinfo.thumbnail !== 'undefined') {
      thumbnail = player.videoinfo.thumbnail;
    }

    if (typeof player.videoinfo.title !== 'undefined') {
      title = player.videoinfo.title;
    }

    if (typeof player.videoinfo.primary !== 'undefined') {
      section = player.videoinfo.primary;
    }

    if (typeof player.videoinfo.id !== 'undefined') {
      id = player.videoinfo.id;
    }

    if (typeof player.url !== 'undefined') {
      url = player.url;
    }

    return {
      title,
      image_url: thumbnail,
      section,
      id,
      url
    };
  }

  /**
     * @desc event triggered on player OnMediaStart and OnMediaUnpause events
     * @param isAd True or False for e.data.baseClip.isAd that is passed from player OnMediaStart and OnMediaUnpause events
     * @returns {FsVideo}
     */
  sendPlay (isAd, player, utmObj) {
    // only send parsely tracking on video play when the video clip is not an ad
    if (!isAd) {
      const metadata = this.getTrackingMeta(player);
      this.sendTracking('play', metadata, utmObj);
    }

    return this;
  }

  /**
     * @desc event triggered on player OnMediaStart event
     * @returns {FsVideo}
     */
  sendPause (isAd, player, utmObj) {
    this.isPlaying = false;
    this.isPaused = true;

    // only send parsely tracking on video pause when the video clip is not an ad
    if (!isAd) {
      const metadata = this.getTrackingMeta(player);
      this.sendTracking('pause', metadata, utmObj);
    }

    return this;
  }

  /**
     * @desc Send parsely tracking when video is played or paused
     * parsely doc: https://www.parsely.com/help/integration/video/
     * @param type
     * @param metadata
     * @param utmObj
     */
  sendTracking (type, metadata, utmObj) {
    // if only send parsely tracking when there is video ID and PARSELY is available
    const isParsely = (typeof PARSELY !== 'undefined' && typeof PARSELY.video === 'object');
    if (typeof metadata !== 'undefined' && metadata.id !== 'undefined' && isParsely) {
      /**
             * we only include title, image_url and section for tracking
             * We don't have pub_date_tmsp, tags and authors from the video player info.
             * We can get them, but it will require additional server call for the videos,
             * so we skip including them at this moment
             */
      let urlOverride = (metadata.url !== '') ? metadata.url : (location.origin + location.pathname);
      // let metadata = this.getTrackingMeta();

      metadata.section = utmObj.utm_content || utmObj.utm_section;
      urlOverride = this.addTrackingQuery(utmObj, urlOverride);

      // the following log info for debugging purpose on the console. We will remove it when we release it to prod
      this.log(`
                    VIDEO TRACKING: sending parsely data
                    type: ${type}
                    title: ${metadata.title}
                    thumbnail: ${metadata.image_url}
                    vid: ${metadata.id}
                    urlOverride: ${urlOverride}
                    config: ${JSON.stringify(metadata)} 
            `);

      try {
        if (type === 'play') {
          PARSELY.video.trackPlay(metadata.id, metadata, urlOverride);
        } else if (type === 'pause') {
          PARSELY.video.trackPause(metadata.id, metadata, urlOverride);
        } else {
          console.log('Tracking Type Not Supported', type);
        }
      } catch (err) {
        console.log('Error sending tracking info: ', err);
      }
    }
  }

  /**
     * @desc replace string to a slug-like format
     * @returns String
     * i.e., "This Is A Slug!" = "this-is-a-slug"
     */
  formatStringAsSlug (str) {
    return str.replace(/[^A-Z0-9]+/ig, '-').toLowerCase();
  }

  /**
     * It is trackable for page view if it is on section front page and the video config include video id in url
     * @param string videoId
     * @param string currentUrl
     * @returns {boolean}
     */
  isTrackable (videoId, currentUrl) {
    // return true if id from url match the current url id does not match
    return videoId !== '' && currentUrl.substr(currentUrl.lastIndexOf('/') + 1) !== videoId;
  }

  /**
     * format object for UTM tracking use
     * @param {obj} unformatted utm object
     */
  getUtmObj (obj) {
    const result = {};
    // loop through object and reformatting property names for UTM tracking
    for (const item in obj) {
      switch (item) {
        case 'campaign':
        case 'section':
        case 'source':
        case 'medium':
        case 'content':
        case 'term':
          result['utm_' + item] = obj[item];
          break;
        default:
          break;
      }
    }
    // failover check for "utm_campaign" and "utm_medium"
    result.utm_campaign = result.utm_campaign || 'video_playback_location';
    if (typeof result.utm_medium === 'undefined') {
      if (typeof this.isBB !== 'undefined') {
        this.isSectionFront = document.body.classList.contains('single-fox_sectionfront');
        this.isMediaHub = document.body.classList.contains('single-fox_media_hub');
        this.isBB = this.bodySection ? this.bodySection.classList.contains('body-content-big-board') : false;
      }
      result.utm_medium = (this.isBB) ? 'bb_video_player' : 'video_player';
    }
    return result;
  }

  /**
     * Gets utm params based on the Page Name property from the video config object
     * Format for the page name params is i.e fscom:nfl:section-front:featured-content
     * [0] = site
     * [1] = section/category - (utm_content)
     * [2] = page type - (utm_source)
     * [3] = term - (utm_term)
     * @param {string} pageName
     */
  getUtmParams (pageName) {
    const args = pageName.split(':');
    return {
      site: args[0] || '',
      section: args[1] || '',
      source: args[2] || '',
      term: args[3] || ''
    };
  }

  /**
     * get embed utm params
     * @param {*} isAmp
     * @param {*} category
     */
  getEmbedUtmParams (isAmp, category) {
    return this.getUtmObj({
      medium: isAmp ? 'amp_video_player' : 'embed_video_player',
      source: 'embed-video-page',
      content: category,
      term: ''
    });
  }

  /**
     * send page view to Parsely on video player. We only send the page view on first click on section front pages.
     * We send page view only if the video ID is in the video player url that is available to us
     */
  sendPageViewTracking (player) {
    // add player video id into the set if it is not in the set so that it is only tracked on the first click on that video
    const vid = player.data.vid;
    if (this.isTrackable(vid, player.data.url) && !player.playedVideos.has(vid)) {
      // check if parsely script is loaded
      if (typeof PARSELY !== 'undefined' && typeof PARSELY.beacon === 'object' && typeof PARSELY.beacon.trackPageView === 'function') {
        // failover for utm_content (start with defined utmObj, category name
        player.utmObj.utm_content = player.utmObj.utm_content || player.data.categoryName;

        const trackingObj = {
          url: this.addTrackingQuery(player.utmObj, player.data.url), // URL to be tracked
          urlref: location.href,
          js: 1 // flag indicating that this is a dynamic event, keep it "1"
        };

        // TEMP(FOR STG/PROD): bug found on homepage "fox-five" tracking, but can't recreate on DEV env
        // need reports of these two objects.
        window.utmProdTest = {
          utmObj: player.utmObj,
          trackingObj
        };
        PARSELY.beacon.trackPageView(trackingObj);
      }
      player.playedVideos.add(vid);
    }
  }
}

export default new TrackingUtils();
