export const fsEvents = {
  videoLoad: 'OnLoadReleaseUrl',
  videoBuffer: 'OnMediaBuffer',
  videoEnd: 'OnMediaComplete',
  videoChapterEnd: 'OnMediaEnd',
  videoError: 'OnMediaError',
  playerError: 'OnPlayerError',
  videoLoading: 'OnMediaLoadStart',
  videoReady: 'OnLoadRelease',
  videoPause: 'OnMediaPause',
  videoPlay: 'OnMediaPlay',
  videoProgress: 'OnMediaPlaying',
  videoSeekStart: 'OnSeekStart',
  videoSeekEnd: 'OnSeekComplete',
  videoStart: 'OnMediaStart',
  videoResume: 'OnMediaUnpause',
  videoMute: 'OnMute',
  playerReady: 'OnPlayerLoaded',
  playerOverlayUp: 'OnShowPlayOverlay',
  playbackStart: 'OnPlaybackStart',
  playbackComplete: 'OnPlaybackComplete',
  playbackInterrupted: 'OnPlaybackInterrupted',
  playbackPaused: 'OnPlaybackPaused',
  playbackResumed: 'OnPlaybackResumed',
  bufferStart: 'OnBufferStart',
  bufferEnd: 'OnBufferEnd',
  buffering: 'OnBuffering',
  contentStart: 'OnContentStart',
  contentComplete: 'OnContentComplete',
  contentPlaying: 'OnContentPlaying',
  adStart: 'OnAdStart',
  adComplete: 'OnAdComplete',
  adProgress: 'OnAdPlaying',
  adBufferStart: 'OnAdBufferStart',
  adBufferEnd: 'OnAdBufferEnd',
  adPodStart: 'OnAdPodStart',
  adPodComplete: 'OnAdPodComplete',
  adPause: 'OnAdPause',
  adResume: 'OnAdResume',
  restartStart: 'onRestartButtonClicked',
  exitEventStream: 'OnExitEventStream',
  slatePlaying: 'OnSlatePlaying',
  slateStart: 'OnSlateStart',
  slateComplete: 'OnSlateComplete'
};

class FsVideoEventHandler {
  constructor () {
    this.events = {};
  }

  dispatchEvent (eventName, data) {
    if (!this.events.hasOwnProperty(eventName)) { return; }
    this.events[eventName].callbacks.forEach((cb) => {
      cb(data);
    });
  }

  addEventListener (eventName, callback) {
    if (!this.events.hasOwnProperty(eventName)) {
      this.events[eventName] = {
        callbacks: []
      };
    }
    this.events[eventName].callbacks.push(callback);
  }

  removeEventListener (eventName, callback) {
    if (this.events.hasOwnProperty(eventName)) {
      const index = this.events[eventName].indexOf(callback);

      if (index > -1) {
        this.events[eventName].splice(index, 1);
      }
    }
  }

  onVideoStart (callback) {
    this.addEventListener(fsEvents.videoStart, callback);
  }

  onVideoPause (callback) {
    this.addEventListener(fsEvents.videoPause, callback);
  }

  onVideoEnd (callback) {
    this.addEventListener(fsEvents.videoEnd, callback);
  }

  onVideoError (callback) {
    this.addEventListener(fsEvents.videoError, callback);
  }

  onPlayerError (callback) {
    this.addEventListener(fsEvents.playerError, callback);
  }

  onPlayerLoaded (callback) {
    this.addEventListener(fsEvents.playerReady, callback);
  }

  onPlayerRestart (callback) {
    this.addEventListener(fsEvents.restartStart, callback);
  }

  onVideoProgress (callback) {
    this.addEventListener(fsEvents.videoProgress, callback);
  }

  // segment analytics related events
  onPlaybackStart (callback) {
    this.addEventListener(fsEvents.playbackStart, callback);
  }

  onPlaybackComplete (callback) {
    this.addEventListener(fsEvents.playbackComplete, callback);
  }

  onContentStart (callback) {
    this.addEventListener(fsEvents.contentStart, callback);
  }

  onContentComplete (callback) {
    this.addEventListener(fsEvents.contentComplete, callback);
  }

  onContentPlaying (callback) {
    this.addEventListener(fsEvents.contentPlaying, callback);
  }

  onSlatePlaying (callback) {
    this.addEventListener(fsEvents.slatePlaying, callback);
  }

  onExitEventStream (callback) {
    this.addEventListener(fsEvents.exitEventStream, callback);
  }

  onAdComplete (callback) {
    this.addEventListener(fsEvents.adComplete, callback)
  }
}

const fsVideoEventHandler = new FsVideoEventHandler();
window.FsVideoEventHandler = fsVideoEventHandler;
export default fsVideoEventHandler;
